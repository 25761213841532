export const experience = [
  {
    id: 1,
    title: "Full Stack Developer",
    subtitle: "IDEMIA · Full-time",
    description: "Working on mission-critical identity and security systems across web and mobile platforms.",
    startDate: "2023-10-01",
    endDate: null, 
    type: "job",
    iconType: "work",
    color: "#3B82F6",
    techStack: ["Angular", "Docker", "React Native", "Kotlin", "Python", "C#"],
  },
  {
    id: 2,
    title: "Application Developer",
    subtitle: "Accenture · Apprenticeship",
    description: "Developed internal tools and APIs, supported CI pipelines.",
    startDate: "2022-08-01",
    endDate: "2023-04-30",
    type: "job",
    iconType: "work",
    color: "#3B82F6",
    techStack: ["C#", "MS SQL", "API", "CI"],
  },
  {
    id: 3,
    title: "Business Analyst",
    subtitle: "Accenture · Apprenticeship",
    description: "Conducted UAT and business analysis for client transformation projects.",
    startDate: "2021-05-01",
    endDate: "2021-08-31",
    type: "job",
    iconType: "work",
    color: "#3B82F6",
    techStack: ["MS SQL", "UAT"],
  },
  {
    id: 4,
    title: "Business Development Executive",
    subtitle: "Continental Electronics · Full-time",
    description: "Supported business outreach, prototyping using microcontrollers.",
    startDate: "2019-08-01",
    endDate: "2020-08-31",
    type: "job",
    iconType: "work",
    color: "#3B82F6",
    techStack: ["MakeCode", "C++", "Arduino", "micro:bit"],
  },
  {
    id: 5,
    title: "Technical Support Associate",
    subtitle: "CapitaLand · Internship",
    description: "Managed content on Adobe Experience Manager and internal CMS.",
    startDate: "2018-02-01",
    endDate: "2018-07-31",
    type: "job",
    iconType: "work",
    color: "#3B82F6",
    techStack: ["Adobe AEM", "CMS"],
  },
  {
    id: 6,
    title: "BSc (Hons) Computing Science",
    subtitle: "Singapore Institute of Technology · Joint Degree with University of Glasgow",
    description: "Graduated with a strong foundation in Information Technology and advanced computing topics.",
    startDate: "2020-08-01",
    endDate: "2023-08-01",
    type: "school",
    iconType: "school",
    color: "#EC4899" 
  },
  {
    id: 7,
    title: "Diploma in Business Application",
    subtitle: "Republic Polytechnic",
    description: "Specialized in software development and business systems.",
    startDate: "2016-04-01",
    endDate: "2019-05-01",
    type: "school",
    iconType: "school",
    color: "#EC4899" 
  },
  {
    id: 8,
    title: "Kubernetes Fundamentals",
    subtitle: "VMware",
    description: "Issued May 2022 · Credential ID: vmw-03063953a-03007007",
    startDate: "2022-05-01",
    endDate: "2022-05-01",
    type: "certification",
    iconType: "cert",
    color: "#10B981" 
  },
  {
    id: 9,
    title: "Docker Fundamentals",
    subtitle: "VMware",
    description: "Issued May 2022 · Credential ID: vmw-03063953a-03007007",
    startDate: "2022-05-01",
    endDate: "2022-05-01",
    type: "certification",
    iconType: "cert",
    color: "#10B981"
  }
];
